import { auth0 } from './environment';

const loginClick = (method) => {
    //telemetryService.trackMilestone("Login clicked");
    window.gtag('event', 'login', { 'method': method });
    window.location.href = `${auth0.host}/authorize?response_type=code&client_id=${auth0.clientId}&redirect_uri=${auth0.redirect}&scope=openid%20profile%20offline_access&state=f`;
}

const createAccount = (method) => {
    //telemetryService.trackMilestone("Create account clicked");
    window.gtag('event', 'sign_up', { 'method': method });
    window.location.href = `${auth0.host}/authorize?response_type=code&client_id=${auth0.clientId}&redirect_uri=${auth0.redirect}&scope=openid%20profile%20offline_access&state=f&screen_hint=signup`;
}

const iphoneAppDownload = (method) => {
    //telemetryService.trackMilestone("Create account clicked");
    var appleLink = 'https://apps.apple.com/app/apple-store/id1636068494';
    const params = new URLSearchParams(window.location.search);
    
    if(params.has("utm_campaign"))
    {
        appleLink = `${appleLink}&ct=${encodeURIComponent(params.get("utm_campaign"))}`;
    }

    window.gtag('event', 'app_download_iphone', { 'method': method });
    window.location.href = appleLink;
    //window.location.href = 'https://apps.apple.com/us/app/practiceloop/id1636068494?itsct=apps_box_badge&amp;itscg=30200';
}

const iphoneRedeemCode = (code) => {
    var appleLink = `https://apps.apple.com/redeem/?ctx=offercodes&id=1636068494&code=${code}`;
    window.gtag('event', 'app_redeem_iphone', { 'code': code });
    window.location.href = appleLink;
}

const androidAppDownload = (method) => {
    //telemetryService.trackMilestone("Create account clicked");
    var googleLink = 'https://play.google.com/store/apps/details?id=com.ormondcode.practiceloop';
    const params = new URLSearchParams(window.location.search);

    if(params.has("utm_campaign"))
    {
        googleLink = `${googleLink}&utm_campaign=${encodeURIComponent(params.get("utm_campaign"))}`;
    }

    if(params.has("utm_source"))
    {
        googleLink = `${googleLink}&utm_source=${encodeURIComponent(params.get("utm_source"))}`;
    }

    window.gtag('event', 'app_download_android', { 'method': method });
    window.location.href = googleLink;
}

const androidRedeemCode = (code) => {
    var androidLink = `https://play.google.com/redeem?code=${code}&id=com.ormondcode.practiceloop`;
    window.gtag('event', 'app_redeem_android', { 'code': code });
    window.location.href = androidLink;
}

export {loginClick, createAccount, iphoneAppDownload, androidAppDownload, iphoneRedeemCode, androidRedeemCode}
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifi, faMobileAlt, faLaptop, faTabletAlt } from '@fortawesome/free-solid-svg-icons'
import { androidAppDownload, iphoneAppDownload } from '../link-actions';

const CricketNew = () => {
    return (
        <>
            <section id="home_cricket" className="atf-cover-bg atf-hero-area atf-align-items-details aft-width-two" style={{ backgroundImage: "url(assets/img/bg/bg.jpg)", backgroundSize: "cover", backgroundPosition: "center center" }}>
                <div className="container">
                    <div className="row atf-content-details align-items-center">
                        <div className="col-lg-7 col-md-6 col-12 atf-single-details text-lg-left text-center" style={{ padding: "20px 0 20px 0" }}>
                            <h2>Video feedback technology.<br />For cricket coaches.</h2>
                        </div>
                        <div className="col-lg-5 col-md-6 col-12 atf_home_img text-center">
                            <div className="atf-img-inner wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.4s" data-wow-offset="0">
                                <div className="home-image-box">

                                    <div>
                                        <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '130px' }}></FontAwesomeIcon>
                                        <h3>1. Video from your phone</h3>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faWifi} style={{ color: '#783F8E', fontSize: '50px', transform: "rotate(180deg)", opacity: "0.5", margin: "20px 0 30px 0" }} ></FontAwesomeIcon>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faLaptop} style={{ color: 'var(--thm-color)', fontSize: '40px', marginBottom: '5px' }}></FontAwesomeIcon>
                                        <FontAwesomeIcon icon={faTabletAlt} style={{ color: 'var(--thm-color)', fontSize: '60px', margin: '0px 20px 0 20px' }}></FontAwesomeIcon>
                                        <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '40px', margin: '0px 25px 5px 5px' }}></FontAwesomeIcon>
                                        <h3>2. See an instant replay on a second device</h3>
                                        <p>(Laptop, Tablet, or another phone.)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="row" style={{ backgroundColor: "yellowgreen", padding: "20px 0 20px 0" }}>
                <div className="col-12 align-self-center text-center">
                    <div>Become a better coach. Install the app for free.</div>
                    <a href="#none" onClick={() => iphoneAppDownload('cricket_cta_1')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                    <a href="#none" onClick={() => androidAppDownload('cricket_cta_1')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                </div>
            </div>

            <section id="how" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <h2>Our secret?<br />A second device</h2>
                                <div className="animated-line"></div>
                                <p><strong>Good coaches</strong> know the importance of video feedback.<br />PracticeLoop makes video even better by streaming the replay to a <strong>second device</strong>.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row clearfix align-self-center">

                        <div className="col-xl-6 col-lg-6 col-12 align-self-center">
                            <div className="atf-section-title atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.4s" data-wow-offset="0">
                                    <video id="loopvideo" preload="metadata" poster="/cricket_hero.png" style={{ width: "100%", maxWidth: "400px" }}
                                        controls={true} autoPlay={true} muted={true} loop={true} playsInline={true}>
                                        <source src="/cricket_hero.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <h4 style={{ margin: "80px 0 80px 0", color: "yellowgreen" }}>"If you use video as a coaching tool. <br />Then you need PracticeLoop."</h4>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 align-self-center">
                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <h3>Video alone is not enough</h3></div>
                                            <div className="atf-feature-text text-left">
                                                <p>Recording and replaying video from a single device takes time. During this time the player forgets what their action <i>"felt"</i> like.</p>
                                                <p className="highlight">See the replay within seconds of performing the skill with PracticeLoop.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <h3>Fast feedback<br />Fast improvement</h3></div>
                                            <div className="atf-feature-text">
                                                <p>PracticeLoop allows a player to watch the replay after every delivery.</p>
                                                <p className="highlight">Meaning they can learn, change, and improve after every delivery.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="elite_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <h2>Portability and price</h2>
                                <div className="animated-line"></div>

                            </div>
                        </div>
                    </div>

                    <div className="row clearfix align-self-center">

                        <div className="col-xl-12 col-lg-12 col-12 align-self-center  text-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h3 style={{ margin: "30px 0 30px 0" }}>Video feedback technology has long been used in high performance indoor nets such as the M.C.G.</h3>
                                <img src="./phone_batting_frame.png" alt="Video on your phone" style={{ width: "130px" }} />
                                <h4 style={{ color: "yellowgreen", margin: "50px 0 50px 0" }}>Now you can have a world class video replay system, on your phone</h4>
                            </div>
                            <div className="row" style={{ backgroundColor: "yellowgreen", padding: "20px 0 20px 0" }}>
                                <div className="col-12 align-self-center text-center">
                                    <h3 style={{ marginBottom: "30px" }}>Take it wherever you want.  Outdoor nets, Indoors nets, the local park.</h3>
                                    <h3 style={{ color: "white", marginBottom: "30px" }}>No need to install expensive equipment.</h3>
                                    <h5>Simply install the app on your phone, and connect a second device.</h5>
                                    <a href="#none" onClick={() => iphoneAppDownload('cricket_cta_1')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                                    <a href="#none" onClick={() => androidAppDownload('cricket_cta_1')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                                    <p>The second device can be a laptop, tablet, or even another phone.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section id="who-for" className="atf-setup-area atf-section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-6">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <h2>For cricketers, coaches, and parents.</h2>
                                <div className="animated-line"></div>
                                <p className="mx-auto my-auto">PracticeLoop helps you learn and teach.</p>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-12 align-self-center">
                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <i className="fa fa-users"></i><h3>Cricketers</h3></div>
                                            <div className="atf-feature-text text-left">
                                                <p>Get important visual feedback to ensure you are practicing correctly.</p>
                                                <p>See this feedback immediately so you can improve faster.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 align-self-center">
                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <i className="fa fa-clipboard-list"></i><h3>Coaches</h3></div>
                                            <div className="atf-feature-text text-left">
                                                <p>Give players more value from your coaching.</p>
                                                <p>Develop better players, become a better coach.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 align-self-center">
                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <i className="fa fa-user"></i> <i className="fa fa-user" style={{ fontSize: '30px' }}></i><h3>Parents</h3></div>
                                            <div className="atf-feature-text text-left">
                                                <p>Your child knows what a good technique looks like. They just don't know what their technique looks like.</p>
                                                <p>Give that awareness and watch them thrive.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="atf-setup-area atf-section-padding">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <h2>QUICK SETUP</h2>
                                <div className="animated-line"></div>
                                <p className="mx-auto my-auto">Watch this short video to learn how to set up PracticeLoop</p>
                            </div>

                            <video id="loopvideo" preload="metadata" style={{ marginTop: '25px', width: "100%", maxWidth: "400px", boxShadow: '0px 0px 20px 10px #66666666' }}
                                controls={true} autoPlay={false} muted={false} loop={false} playsInline={true}>
                                <source src="/setup_video.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="atf-pricing-area atf-section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-xl-6">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 className="">Features</h2>
                            </div>
                        </div>
                    </div>


                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 atf-pricing-item">
                            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-pricing-heading">
                                    <div className="atf-pricing-name">
                                        <h2 className="offer-list-none">Free</h2>
                                        <h4>Basic</h4>
                                    </div>
                                </div>
                                <div className="atf-pricing-body">
                                    <ul>
                                        <li>Realtime view</li>
                                        <li>Delayed replay</li>
                                        <li className="offer-list-none"><del>Pause and analyse</del></li>
                                        <li className="offer-list-none"><del>Checkpoints</del></li>
                                        <li className="offer-list-none"><del>Email support</del></li>
                                        <li>&nbsp;</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 atf-pricing-item">
                            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div className="atf-pricing-heading">
                                    <div className="atf-pricing-name">
                                        <h2>$3.99* per year <span style={{ fontSize: '12px' }}>*USD</span></h2>
                                        <h4>Pro</h4>
                                    </div>
                                </div>
                                <div className="atf-pricing-body">
                                    <ul>
                                        <li>Realtime view</li>
                                        <li>Delayed replay</li>
                                        <li>Pause and analyse</li>
                                        <li>Checkpoints</li>
                                        <li>Email support</li>
                                        <li>Free 7 day trial</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row">
                <div className="col-12 align-self-center text-center">
                    <h1>It's time to become a better coach</h1>
                </div>
            </div>

            <div id="get-started" className="atf-section-padding atf-footer-offset">
                <div className="atf-brand-overlay">
                    <div className="container">
                        <div className="row justify-content-center clearfix wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                            <div className="col-lg-8 col-md-8 col-11 text-center atf-mailchamp-headding atf-mailchamp-subscribe">
                                <h2 className="mb-3 text-center">Try the app for free</h2>
                                <div>
                                    <a href="#none" onClick={() => iphoneAppDownload('home_bottom_cta')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                                    <a href="#none" onClick={() => androidAppDownload('home_bottom_cta')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ >
    );
}

export default CricketNew;


import React from 'react';

const practiceLight = {
  fontFamily: 'Comfortaa',
  color: 'white',
};

const practiceDark = {
  fontFamily: 'Comfortaa',
  color: '#783F8E',
};

const loopLight = {
  fontFamily: 'Comfortaa',
  color: 'yellowgreen',
};

const loopDark = {
  fontFamily: 'Comfortaa',
  color: 'yellowgreen',
};

function LogoText(props){
    var practiceStyle = props.light ? practiceLight : practiceDark;
    var loopStyle = props.light ? loopLight : loopDark;

    return (
      <span style={props.style}>
        <span style={practiceStyle}>practice</span><span style={loopStyle}>Loop</span>
      </span>
    );
}

export default LogoText;
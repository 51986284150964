import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faTabletAlt, faLaptop, faRedo } from '@fortawesome/free-solid-svg-icons'
import { androidAppDownload, iphoneAppDownload } from '../link-actions';
import LogoText from '../components/logoText';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const PracticeLoop = () => {
	return (<>
		<section id="home" className="atf-cover-bg atf-hero-area atf-align-items-details aft-width-two" style={{ backgroundImage: "url(assets/img/bg/bg.jpg)", backgroundSize: "cover", backgroundPosition: "center center" }}>
			<div className="container">
				<div className="row atf-content-details align-items-center">
					<div className="col-lg-7 col-md-6 col-12 atf-single-details text-lg-left text-center">
						<h2>Improve any physical skill. Faster!</h2>
						<div className="col-12 text-center cta-panel">
							<a href="#none" onClick={() => iphoneAppDownload('top_banner')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
							<a href="#none" onClick={() => androidAppDownload('top_banner')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
						</div>

					</div>
					<div className="col-lg-5 col-md-6 col-12 atf_home_img text-center">
						<div className="atf-img-inner wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.4s" data-wow-offset="0">
							<div className="home-image-box">
								<div style={{ marginTop: '10px', marginBottom: '25px' }}>
									<LogoText style={{ fontSize: '26px' }} />
									<p style={{ fontSize: '11px', marginTop: '-8px' }}>Skill improvement with each loop</p>
								</div>

								<div>
									<FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '60px' }}></FontAwesomeIcon>
									<h3 className="shake-1">Execute</h3>
									<p>Perform skill in front of your phone camera</p>
								</div>
								<div>
									<FontAwesomeIcon icon={faRedo} className="loopSpinner"></FontAwesomeIcon>
								</div>
								<div>
									<FontAwesomeIcon icon={faLaptop} style={{ color: 'var(--thm-color)', fontSize: '40px', marginBottom: '5px' }}></FontAwesomeIcon>
									<FontAwesomeIcon icon={faTabletAlt} style={{ color: 'var(--thm-color)', fontSize: '60px', margin: '0px 20px 0 20px' }}></FontAwesomeIcon>
									<FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '40px', margin: '0px 25px 5px 5px' }}></FontAwesomeIcon>
									<h3 className="shake-2">Learn and improve</h3>
									<p>Stream real-time video to a second device</p>
								</div>
							</div>
						</div>

					</div>

				</div>
			</div>
		</section>

		<section id="how" className="atf-about-area atf-section-padding atf-shape">
			<img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-8 col-lg-8">
						<div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
							<h2>What is PracticeLoop?</h2>
							<div className="animated-line"></div>
							<p><strong>PracticeLoop</strong> is an app that streams video from your phone, and shows both a <strong>real-time view</strong> and <strong>instant replay</strong> on a second device.</p>
						</div>
					</div>
				</div>

				<div className="row clearfix align-self-center">

					<div className="col-xl-6 col-lg-6 col-12 align-self-center">
						<div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
							<div className="atf-about-image"><img src="hero_transparent_2.png" alt="about" className="card-s" /></div>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-12 align-self-center">
						<div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
							<div className="atf-section-title atf-feature-content">
								<div className="atf-single-feature">
									<div className="atf-feature-section">
										<div className="atf-feature-icon atf-feature-heading">
											<h3>Increase self awareness</h3></div>
										<div className="atf-feature-text text-left">
											<p>Most people 'feel' completely different to how they look, and end up practicing the wrong technique.</p>
											<p className="highlight">PracticeLoop provides real-time feedback to help you correct mistakes quickly.</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
							<div className="atf-section-title atf-feature-content">
								<div className="atf-single-feature">
									<div className="atf-feature-section">
										<div className="atf-feature-icon atf-feature-heading">
											<h3>Faster improvement</h3></div>
										<div className="atf-feature-text">
											<p>Traditional video feedback involves stopping your activity to view the video. By the time you see the feedback, you forget how it 'felt'.</p>
											<p className="highlight">Review without interruption on a second device.</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
							<div className="atf-section-title atf-feature-content">
								<div className="atf-single-feature">
									<div className="atf-feature-section">
										<div className="atf-feature-icon atf-feature-heading">
											<h3>Any angle</h3></div>
										<div className="atf-feature-text">
											<p>Mirrors are popular visual feedback tools, but you can only see certain angles without twisting your head.</p>
											<p className="highlight">The use of a second device lets you view from any angle.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<div className="row">
			<div className="col-12 align-self-center text-center">
				<div>Install the app for free</div>
				<a href="#none" onClick={() => iphoneAppDownload('home_bottom_cta')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
				<a href="#none" onClick={() => androidAppDownload('home_bottom_cta')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
			</div>
		</div>

		<section id="who-for" className="atf-setup-area atf-section-padding">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-6 col-lg-6">
						<div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
							<h2>For athletes, coaches, and parents.</h2>
							<div className="animated-line"></div>
							<p className="mx-auto my-auto">PracticeLoop helps you learn and teach.</p>
						</div>
					</div>
				</div>


				<div className="row">
					<div className="col-xl-4 col-lg-4 col-12 align-self-center">
						<div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
							<div className="atf-section-title atf-feature-content">
								<div className="atf-single-feature">
									<div className="atf-feature-section">
										<div className="atf-feature-icon atf-feature-heading">
											<i className="fa fa-basketball-ball"></i><h3>Athletes</h3></div>
										<div className="atf-feature-text text-left">
											<p>Get important visual feedback to ensure you are practicing your skill correctly.</p>
											<p>See this feedback immediately so you can improve faster.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-lg-4 col-12 align-self-center">
						<div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
							<div className="atf-section-title atf-feature-content">
								<div className="atf-single-feature">
									<div className="atf-feature-section">
										<div className="atf-feature-icon atf-feature-heading">
											<i className="fa fa-clipboard-list"></i><h3>Coaches</h3></div>
										<div className="atf-feature-text text-left">
											<p>Students 'get' what you are teaching them faster if they can see it.</p>
											<p>Make visual checkpoints to stop regression once the coaching session is over.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-lg-4 col-12 align-self-center">
						<div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
							<div className="atf-section-title atf-feature-content">
								<div className="atf-single-feature">
									<div className="atf-feature-section">
										<div className="atf-feature-icon atf-feature-heading">
											<i className="fa fa-user"></i> <i className="fa fa-user" style={{ fontSize: '30px' }}></i><h3>Parents</h3></div>
										<div className="atf-feature-text text-left">
											<p>Your child knows what a good technique looks like. They just don't know what their technique looks like.</p>
											<p>Give that awareness and watch them thrive.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<p></p>
					<div className="clearfix">
						<div className="col-12">
							<div className="row justify-content-center">
								<div className="col-xl-6 col-lg-6">
									<div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
										<p>&nbsp;</p>
										<h2 className="">For any sport</h2>
										<div className="animated-line"></div>
										<p className="mx-auto my-auto">Where technique and form are important.</p>
										<p></p>
									</div>
								</div>
							</div>
							<OwlCarousel className="atf-brand-active" autoplayTimeout={2100} smartSpeed={2000} autoplay loop animateIn margin={10} responsive={{
								0: {
									items: 2
								},
								600: {
									items: 3
								},
								1000: {
									items: 4
								}
							}}>
								<div className="atf-brand-content">
									<div className="atf-brand-icon">
										<strong>Cricket</strong>
									</div>
								</div>
								<div className="atf-brand-content">
									<div className="atf-brand-icon">
										<strong>Golf</strong>
									</div>
								</div>
								<div className="atf-brand-content">
									<div className="atf-brand-icon">
										<strong>Fitness</strong>
									</div>
								</div>

								<div className="atf-brand-content">
									<div className="atf-brand-icon">
										<strong>Ballet</strong>
									</div>
								</div>

								<div className="atf-brand-content">
									<div className="atf-brand-icon">
										<strong>Football</strong>
									</div>
								</div>
								<div className="atf-brand-content">
									<div className="atf-brand-icon">
										<strong>Basketball</strong>
									</div>
								</div>
								<div className="atf-brand-content">
									<div className="atf-brand-icon">
										<strong>Tennis</strong>
									</div>
								</div>
							</OwlCarousel>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="examples" className="atf-section-padding" style={{ backgroundImage: 'url(assets/img/bg/bg-3b.png)', backgroundSize: 'cover', backgroundPosition: 'center center' }}>
			<div className="container">
				<div className="row justify-content-center clearfix">
					<div className="col-xl-7 col-lg-7 col-12 ">
						<div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
							<h2>Watch</h2>
							<div className="animated-line"></div>
							<p>This cricketer is using PracticeLoop to improve his batting technique.</p>
							<div className="watch-list-item">
								<h5><i className="fa fa-mobile-alt"></i>Setup phone from desired angle</h5>
								<h5><i className="fa fa-tablet-alt"></i>Place tablet for easy viewing</h5>
								<h5><i className="fa fa-bolt"></i>Execute skill</h5>
								<h5><i className="fa fa-eye"></i>Review, improve, repeat</h5>
							</div>

						</div>
					</div>
					<div className="col-12 col-lg-5 col-xl-5 align-self-center text-center">
						<video id="loopvideo" preload="metadata" poster="/cricket_hero.png" style={{ width: "100%", maxWidth: "800px", boxShadow: '0px 0px 20px 10px #66666666' }}
							controls={true} autoPlay={true} muted={true} loop={true} playsInline={true}>
							<source src="/cricket_hero.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
					</div></div>
			</div>
		</section>

		<div className="row atf-section-padding">
			<div className="col-12 align-self-center text-center">
				<div>Install the app for free</div>
				<a href="#none" onClick={() => iphoneAppDownload('home_bottom_cta')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
				<a href="#none" onClick={() => androidAppDownload('home_bottom_cta')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
			</div>
		</div>

		<section id="features" className="atf-pricing-area atf-section-padding">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-7 col-xl-6">
						<div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
							<h2 className="">Features</h2>
						</div>
					</div>
				</div>


				<div className="row clearfix">
					<div className="col-lg-6 col-md-6 atf-pricing-item">
						<div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
							<div className="atf-pricing-heading">
								<div className="atf-pricing-name">
									<h4>Free</h4>
								</div>
							</div>
							<div className="atf-pricing-body">
								<ul>
									<li>Delayed replay</li>
									<li className="offer-list-none"><del>Realtime view</del></li>
									<li className="offer-list-none"><del>Cast to second device</del></li>
									<li className="offer-list-none"><del>Pause and analyse</del></li>
									<li className="offer-list-none"><del>Checkpoints</del></li>
									<li className="offer-list-none"><del>Email support</del></li>
									<li>&nbsp;</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="col-lg-6 col-md-6 atf-pricing-item">
						<div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
							<div className="atf-pricing-heading">
								<div className="atf-pricing-name">
									<h4>Pro</h4>
								</div>
							</div>
							<div className="atf-pricing-body">
								<ul>
									<li>Delayed replay</li>
									<li>Realtime view</li>
									<li>Cast to second device</li>
									<li>Pause and analyse</li>
									<li>Checkpoints</li>
									<li>Email support</li>
								</ul>
							</div>
							<h2>
									<div className="row clearfix">
									<div className="col-6">
										<div style={{backgroundColor:"yellowgreen", margin:"10px", borderRadius:"10px"}}>
											<p style={{fontSize:"20px", color:"white", padding:"5px"}}>Monthly</p>
											<span style={{fontSize: '28px'}}>$1.99*</span>
										</div>
									</div>

									<div className="col-6">
										<div style={{backgroundColor:"yellowgreen", margin:"10px", borderRadius:"10px"}}>
											<p style={{fontSize:"20px", color:"white", padding:"5px"}}>Yearly</p>
											<span style={{fontSize: '28px'}}>$12.99*</span>
										</div>
									</div>
									</div>
									</h2>
									<p style={{fontSize:"11px"}}>* USD. Prices vary slightly per region.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<div id="get-started" className="atf-section-padding atf-footer-offset">
			<div className="atf-brand-overlay">
				<div className="container">
					<div className="row justify-content-center clearfix wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
						<div className="col-lg-8 col-md-8 col-11 text-center atf-mailchamp-headding atf-mailchamp-subscribe">
							<h2 className="mb-3 text-center">Install the app to get started</h2>
							<div>
								<a href="#none" onClick={() => iphoneAppDownload('home_bottom_cta')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
								<a href="#none" onClick={() => androidAppDownload('home_bottom_cta')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</>
	);
}

export default PracticeLoop;


import React, { useEffect } from 'react';
import { iphoneAppDownload, androidAppDownload, iphoneRedeemCode } from '../link-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptop, faMobileAlt, faTabletAlt, faArrowCircleRight, faRedo, faGift, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import LogoText from '../components/logoText';

const ShopifyBuyInit = () => {
    var client = window.ShopifyBuy.buildClient({
        domain: '1ed414-b2.myshopify.com',
        storefrontAccessToken: '2d9112aec220f0a27b33457e64c4d7ed',
    });
    window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
            id: '8455129071784',
            node: document.getElementById('product-component-1720938534578'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
                "product": {
                    "styles": {
                        "product": {
                            "@media (min-width: 601px)": {
                                "max-width": "calc(25% - 20px)",
                                "margin-left": "20px",
                                "margin-bottom": "50px"
                            }
                        },
                        "button": {
                            "font-family": "Avant Garde, sans-serif"
                        }
                    },
                    "buttonDestination": "modal",
                    "contents": {
                        "img": false,
                        "title": false,
                        "price": false,
                        "options": false
                    },
                    "text": {
                        "button": "Shop Now"
                    }
                },
                "productSet": {
                    "styles": {
                        "products": {
                            "@media (min-width: 601px)": {
                                "margin-left": "-20px"
                            }
                        }
                    }
                },
                "modalProduct": {
                    "contents": {
                        "img": false,
                        "imgWithCarousel": true,
                        "button": false,
                        "buttonWithQuantity": true
                    },
                    "styles": {
                        "product": {
                            "@media (min-width: 601px)": {
                                "max-width": "100%",
                                "margin-left": "0px",
                                "margin-bottom": "0px"
                            }
                        },
                        "button": {
                            "font-family": "Avant Garde, sans-serif"
                        }
                    },
                    "text": {
                        "button": "Add to cart"
                    }
                },
                "option": {},
                "cart": {
                    "styles": {
                        "button": {
                            "font-family": "Avant Garde, sans-serif"
                        }
                    },
                    "text": {
                        "total": "Subtotal",
                        "button": "Checkout"
                    }
                },
                "toggle": {
                    "styles": {
                        "toggle": {
                            "font-family": "Avant Garde, sans-serif"
                        }
                    }
                }
            },
        });
        /*
                ui.createComponent('product', {
                    id: '8455129071784',
                    node: document.getElementById('product-component-1720938534578_2'),
                    moneyFormat: '%24%7B%7Bamount%7D%7D',
                    options: {
                        "product": {
                            "styles": {
                                "product": {
                                    "@media (min-width: 601px)": {
                                        "max-width": "calc(25% - 20px)",
                                        "margin-left": "20px",
                                        "margin-bottom": "50px"
                                    }
                                },
                                "button": {
                                    "color": "#783F8E",
                                    "border-radius": "20px",
                                    "padding": "10px 30px",
                                    ":hover": {
                                        "color": "#783F8E",
                                        "background-color": "#e6e6e6"
                                    },
                                    "background-color": "#ffffff",
                                    ":focus": {
                                        "background-color": "#e6e6e6"
                                    }
                                },
                            },
                            "buttonDestination": "modal",
                            "contents": {
                                "img": false,
                                "title": false,
                                "price": false,
                                "options": false
                            },
                            "text": {
                                "button": "Shop Now"
                            }
                        },
                        "productSet": {
                            "styles": {
                                "products": {
                                    "@media (min-width: 601px)": {
                                        "margin-left": "-20px"
                                    }
                                }
                            }
                        },
                        "modalProduct": {
                            "contents": {
                                "img": false,
                                "imgWithCarousel": true,
                                "button": false,
                                "buttonWithQuantity": true
                            },
                            "styles": {
                                "product": {
                                    "@media (min-width: 601px)": {
                                        "max-width": "100%",
                                        "margin-left": "0px",
                                        "margin-bottom": "0px"
                                    }
                                },
                                "button": {
                                    "font-family": "Avant Garde, sans-serif"
                                }
                            },
                            "text": {
                                "button": "Add to cart"
                            }
                        },
                        "option": {},
                        "cart": {
                            "styles": {
                                "button": {
                                    "font-family": "Avant Garde, sans-serif"
                                }
                            },
                            "text": {
                                "total": "Subtotal",
                                "button": "Checkout"
                            }
                        },
                        "toggle": {
                            "styles": {
                                "toggle": {
                                    "font-family": "Avant Garde, sans-serif"
                                }
                            }
                        }
                    },
                });*/
    });
};
export const GolfVideo = () => {
    useEffect(() => {
        ShopifyBuyInit();
    }, [ShopifyBuyInit]);

    return (
        <>
            <div className="non-landing-banner"></div>
            <section className="atf-about-area atf-section-padding atf-shape">
                <div className="container">

                    <div className="row clearfix align-self-center">

                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 style={{ marginBottom: "10px", fontSize: '26px' }}><span style={{ color: "yellowgreen" }}>Don't record your swing.</span> Stream it!</h2>
                                <div style={{ borderTop: "2px dotted yellowgreen", padding: "5px", marginBottom: "20px" }}>
                                    <p style={{ fontSize: '16px', color: "var(--thm-black)" }}>Swing, then watch an instant replay, on a second device</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                            <img src="hero_transparent_2.png" alt="about" className="card-s" />
                        </div>
                    </div>

                </div>
            </section>


            <div className="container">
                <div className="row" style={{ backgroundColor: "yellowgreen", padding: "20px 0 20px 0" }}>
                    <div className="col-12 align-self-center text-center">
                        <p style={{ fontSize: "28px", color: "var(--thm-black)", marginBottom: "20px" }}>Olympics Special</p>
                        <p style={{ fontSize: "16px", color: "white", marginBottom: "0px", lineHeight: '1.5' }}>Use promo code <strong style={{color:"var(--thm-black)"}}>PARIS24</strong> to try<br /> <span style={{fontSize: '24px'}}>Free for the first month</span> </p>
                        <p style={{ fontSize: "12px", color: "var(--thm-black)", marginBottom: "5px" }}>(Normally 7 days, offer ends 12 Aug 24)</p>
                        <div>
                            <button className="storeButton" onClick={() => iphoneRedeemCode('PARIS24')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></button>
                            <button className="storeButton" onClick={() => androidAppDownload('golf_video_bottom_cta')}><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></button>

                        </div>
                        <h3 style={{ fontSize: '20px' }}>THEN ONLY $1.99*<span style={{ fontSize: "14px" }}>/month</span></h3>
                        <div style={{ color: "#EEEEEE", fontSize: "12px" }}>*USD. Prices vary slightly per region. Offer ends 12 Aug 2024. <br />Cancel any time.</div>

                    </div>
                </div>
            </div>
            <section id="batting_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row clearfix align-self-center">

                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div style={{ margin: '30px 10px 10px 10px' }}>
                                    <h2 style={{ marginBottom: "10px", fontSize: '26px', lineHeight: "1.5" }}>Stream an instant replay<br /><span style={{ color: "#999999" }}>from your</span> <span style={{ color: "yellowgreen" }}>phone,</span><br /><span style={{ color: "#999999" }}>to a </span><span style={{ color: "yellowgreen" }}>tablet or laptop</span></h2>
                                </div>
                                <p style={{ fontSize: "16px", marginTop: "25px" }}>Watch the replay instantly, at your feet</p>
                                <p>&nbsp;</p>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-12 text-center  align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <img src="./watch_at_feet.png" alt="Replay at your feet" style={{ width: "100%", maxWidth: "800px" }} ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="who-for" className="atf-setup-area atf-section-padding">
                <div className="container">
                    <div className="row clearfix align-self-center">
                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 style={{ margin: '0px 10px 10px 10px', fontSize: '26px', lineHeight: "1.5" }}>Just swing, and watch.</h2>
                                <p style={{ fontSize: "16px", marginTop: "5px" }}>Never walk back to your phone to watch the recording again!</p>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <img src="./driving_range_pov_2.png" alt="See instant replay at your feet" style={{ width: "100%", maxWidth: "800px", marginTop: "20px" }} ></img>
                                <h2 style={{ margin: '20px 10px 0px 10px', fontSize: '18px', lineHeight: "1.5", color: "yellowgreen" }}>Watch the replay as you practice</h2>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section id="batting_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row clearfix align-self-center">

                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 style={{ margin: '30px 10px 10px 10px', fontSize: '26px', lineHeight: "1.5" }}>
                                    Pause and analyse frame by frame
                                </h2>
                                <p style={{ fontSize: "16px" }}>Use the <strong>time slider</strong> to view your swing in more detail</p>
                                <p>&nbsp;</p>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <img src="./driving_range_pause.png" alt="Batting" style={{ width: "100%", maxWidth: "800px" }} ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {false && <div className="container">
                <div className="row" style={{ backgroundColor: "yellowgreen", padding: "10px 0 0 0" }}>
                    <div className="col-12 align-self-center text-center">
                        <div className="row">
                            <div className="col-3">
                                <img src="./tripod_special.png" alt="Real time view" ></img>
                            </div>
                            <div className="col-9">
                                <h3 style={{ margin: '0px 10px 5px 10px', fontSize: '26px', lineHeight: "1.5", color: "white" }}>TRIPOD SPECIAL</h3>

                                <p style={{ fontSize: "20px", color: "var(--thm-black)" }}>Get 90 days free with any tripod purchase.</p>
                                <div id='product-component-1720938534578_2'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <div className="container">
                <div className="row" style={{ backgroundColor: "yellowgreen", padding: "20px 0 20px 0" }}>
                    <div className="col-12 align-self-center text-center">
                        <h3 style={{ margin: '10px 10px 0px 10px', fontSize: '26px', lineHeight: "1.5", color: "white" }}>Get the first month free</h3>
                        <p style={{ fontSize: "12px", color: "white", marginBottom: "5px" }}>(Normally 7 days)</p>
                        <p style={{ fontSize: "16px", color: "var(--thm-black)", marginBottom: "5px" }}>Use promo code <strong>PARIS24</strong> to get our Olympic games special</p>
                        <div>
                            <button className="storeButton" onClick={() => iphoneRedeemCode('PARIS24')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></button>
                            <button className="storeButton" onClick={() => androidAppDownload('golf_video_bottom_cta')}><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></button>

                        </div>
                        <h3 style={{ fontSize: '16px' }}>THEN ONLY $1.99*<span style={{ fontSize: "10px" }}>/month</span></h3>
                        <div style={{ color: "#EEEEEE", fontSize: "12px" }}>*USD. Prices vary slightly per region.<br />Offer ends 12 Aug 2024. Cancel any time.</div>

                    </div>
                </div>
            </div>

            <section className="atf-section-padding" style={{ paddingTop: "0px", backgroundImage: 'url(assets/img/bg/bg-3b.png)', backgroundSize: 'cover', backgroundPosition: 'center center', paddingBottom: "0px" }}>
                <div className="container">
                    <div className="row justify-content-center" style={{ backgroundColor: "var(--thm-black)" }}>
                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div style={{ margin: '30px 10px 10px 10px' }}>
                                    <h2 style={{ marginBottom: "10px", fontSize: '26px', lineHeight: "1.5", color: "white" }}>Fast feedback is fast improvement</h2>
                                </div>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center clearfix">
                        <div className="col-lg-6 col-md-6 col-12 atf_home_img text-center">
                            <div className="atf-img-inner wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.4s" data-wow-offset="0">
                                <div className="home-image-box">
                                    <p style={{ fontSize: "16px", marginBottom: "20px" }}><strong><LogoText light={false} /></strong> <span style={{ color: "#999999" }}>allows you to get crucial visual feedback, without stopping your practice.</span></p>
                                    <div>
                                        <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '60px' }}></FontAwesomeIcon>
                                        <h3 className="shake-1">Swing</h3>
                                        <p>Swing in front of your phone camera</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faRedo} className="loopSpinner"></FontAwesomeIcon>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faLaptop} style={{ color: 'var(--thm-color)', fontSize: '40px', marginBottom: '5px' }}></FontAwesomeIcon>
                                        <FontAwesomeIcon icon={faTabletAlt} style={{ color: 'var(--thm-color)', fontSize: '60px', margin: '0px 20px 0 20px' }}></FontAwesomeIcon>
                                        <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '40px', margin: '0px 25px 5px 5px' }}></FontAwesomeIcon>
                                        <h3 className="shake-2">Watch. Learn. Improve</h3>
                                        <p>Watch immediate replay on a second device</p>

                                        <p style={{ fontSize: "14px", margin: "30px 0 10px 0", lineHeight: "1.8", border: "2px solid yellowgreen", backgroundColor: "#F1F8E4", padding: "15px 0 15px 0", borderRadius: "10px" }}>
                                            Improve faster by reducing your <br /><span style={{ fontSize: "16px" }}>Swing <FontAwesomeIcon icon={faArrowCircleRight} style={{ color: "yellowgreen" }} /> Watch <FontAwesomeIcon icon={faArrowCircleRight} style={{ color: "yellowgreen" }} /> Learn <FontAwesomeIcon icon={faArrowCircleRight} style={{ color: "yellowgreen" }} /> Improve</span><br /> cycle time.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6 align-self-center text-center" style={{ padding: "0px" }}>
                            <img src="./putting_front.png" alt="Any angle" />
                        </div></div>
                </div>
            </section>




            <section id="batting_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row clearfix align-self-center">

                        <div className="col-xl-6 col-lg-6 col-12 align-self-center" style={{ minHeight: "380px" }}>
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 style={{ margin: '30px 10px 30px 10px', fontSize: '26px' }}>
                                    Not just an instant replay <br /><span style={{ color: "yellowgreen" }}>It's a virtual mirror</span>
                                </h2>
                                <p style={{ fontSize: "16px" }} className="text-center my-auto">Use the <strong>Real-Time</strong> stream to see a direct feed from your camera. It acts like a <strong>virtual mirror</strong> that you can carry with you.</p>
                                <img style={{ fontSize: "16px", marginTop: "35px" }} src="./real_time.png" alt="Real time view" ></img>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-12 align-self-center" style={{ minHeight: "350px" }}>
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 style={{ margin: '30px 10px 10px 10px', fontSize: '26px' }}>
                                    "If you record your swing, try <span style={{ color: "yellowgreen" }}>PracticeLoop</span>"
                                </h2>
                                <img src="./chipping.png" style={{ width: "300px" }} alt="Better than normal video" ></img>
                                <p style={{ fontSize: "16px" }} className="text-center my-auto">Our customers love PracticeLoop and you will too.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="features" className="atf-pricing-area atf-section-padding">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-12 atf-pricing-item">
                            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-pricing-heading">
                                    <div className="atf-pricing-name">
                                        <h2 className="offer-list-none">Tripod special</h2>
                                        <h4>First 90 days free</h4>
                                    </div>
                                </div>
                                <div className="row atf-pricing-body">
                                    <img className="col-3" src="./tripod_special.png" alt="Real time view" ></img>
                                    <div className="col-9">
                                        <p>Purchase our super-light, portable tripod, and get a 90 day free trial of the PracticeLoop app.</p>
                                        <div id='product-component-1720938534578'></div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        {1 == 1 &&
                            <div className="col-lg-6 col-md-6 col-12 atf-pricing-item">
                                <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                    <div className="atf-pricing-heading">
                                        <div className="atf-pricing-name">
                                            <h2 className="offer-list-none">Olympics special</h2>
                                            <h4>First month free</h4>
                                            <p style={{ fontSize: "12px", marginTop: "-5px" }}>(Normally 7 days)</p>
                                            <h2 style={{ fontSize: "18px", marginTop: "0px" }}>Then only $1.99*<span style={{ fontSize: "12px" }}>/month</span></h2>
                                        </div>
                                    </div>
                                    <div className="atf-pricing-body">
                                        <p>We are celebrating the 2024 Paris Olympics by giving you a free trial for the first month.</p>
                                        <p>&nbsp;</p>
                                        <p>Use promo code <strong>PARIS24</strong> to redeem</p>
                                        <p>Hurry. Offer expires 12 August 2024.</p>
                                        <button className="storeButton" onClick={() => iphoneRedeemCode('PARIS24')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></button>
                                        <button className="storeButton" onClick={() => androidAppDownload('golf_video_bottom_cta')}><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></button>
                                        <p style={{ fontSize: "10px" }}>*USD. Prices vary slightly per region. Cancel any time.</p>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </section>

            <div id="get-started" className="atf-section-padding atf-footer-offset">
                <div className="atf-brand-overlay">
                    <div className="container">
                        <div className="row justify-content-center clearfix wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                            <div className="col-lg-8 col-md-8 col-11 text-center atf-mailchamp-headding atf-mailchamp-subscribe">
                                <h2 className="text-center" style={{ fontSize: '22px', marginBottom: '5px' }}>DON'T LIKE SPECIALS?</h2>
                                <p style={{ color: "var(--thm-black)" }}>That's ok.  Install the app normally here.</p>
                                <div>
                                    <button className="storeButton" onClick={() => iphoneAppDownload('golf_video_bottom_cta')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></button>
                                    <button className="storeButton" onClick={() => androidAppDownload('golf_video_bottom_cta')}><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


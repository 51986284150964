import React from 'react';
import LogoText from '../components/logoText';
import { androidAppDownload, iphoneRedeemCode } from '../link-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

function InstallApp() {
    return (
        <>
            <div className="non-landing-banner"></div>
            <div style={{ width: '100%', height: '100%', textAlign: 'center', paddingTop: '10vh' }}>
                <img src="logo_dark.png" alt="Logo" style={{ width: '70px', height: 'auto', display: 'inline-block', marginBottom: '5px' }} />
                <br />
                <LogoText style={{ fontSize: "32px" }}></LogoText>
                    <h3 style={{ paddingTop: '2vh' }}>BRITISH OPEN special<br />90 days free!</h3>
                    <p style={{ padding: '15px' }}>Our PRO subscription is only $12.99USD per year (about 2 golf balls)<br /><br />But it gets better... to celebrate the British Open, we're offering you the first 90 days FREE.</p>
                    <p style={{ paddingTop: '2vh' }}>Choose your preferred app store to get started:</p>
                    <div className="col-12 text-center cta-panel">
                        <a href="#none" onClick={() => iphoneRedeemCode('THEOPEN24')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                        <a href="#none" onClick={() => androidAppDownload('golf_video_install')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                    </div>
                    <p style={{ paddingTop: '2vh' }}>Offer ends 23 July 2024</p>
                    <div style={{background:"#FFFFEE", border: "1px solid yellow", margin:"10px", borderRadius:"10px", padding: "10px", fontSize:"12px"}}>
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{color: "orange", fontSize:"16px"}} /><br />
                        To redeem the offer on Android, install and open the PracticeLoop app, choose the yearly subscription, and click Redeem code on checkout. Enter code THEOPEN24 to receive the first 90 days free.<br/><br/>
                        For IOS, simply click the App Store link above.
                    </div>
            </div>
        </>
    );
}

export default InstallApp;

